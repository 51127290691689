<template>
    <div class="basic">
        <van-nav-bar title="客户列表" :fixed=isFixed />
        <!-- <div class="addDiv" @click="addCustomer" v-if="isSalesman">
            <img src="../../assets/image/customerList/add.png" alt="">
        </div> -->
        <div v-show="userType == 'salesman'">
            <van-tabs  @click="onClickSelectStatus">
            <van-tab v-for="(item,index) in statusArr" :title="item.name" :key="index"> 
            </van-tab>
            </van-tabs>
            <div class="selectPlan">
                <div :class="{selectActive : scheduleControl == ''}" @click="toselect('')" >全选</div>
                <div class="center" @click="toselect('discount_scheme')" :class="{selectActive : scheduleControl == 'discount_scheme'}">折扣方案</div>
                <div @click="toselect('storage')" :class="{selectActive : scheduleControl == 'storage'}">储能方案</div>
            </div>
        </div>
        <div v-show="userType == 'distributor' || userType == 'Headquarters'" style="display:flex">
            <!-- 业务员选择 -->
            <div  v-show="userType == 'distributor'">
                <van-field class="field" :value="distributorValue"  @click="showDistributorPicker = true" readonly clickable/>
                <van-popup v-model="showDistributorPicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="distributorArr"
                            @confirm="onDistributorConfirm"
                            @cancel="showDistributorPicker = false"
                            value-key="salesman"
                    />
                </van-popup>
            </div>
            <!-- 经销商选择 -->
            <div v-show="userType == 'Headquarters'">
                <van-field class="field" :value="headerValue"  @click="showHeaderPicker = true" readonly clickable/>
                <van-popup v-model="showHeaderPicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="headerArr"
                            @confirm="onHeaderConfirm"
                            @cancel="showHeaderPicker = false"
                            value-key="titleName"
                    />
                </van-popup>
            </div>
            <!-- 状态筛选 -->
            <van-field class="field" :value="statusValue"  @click="showStatusPicker = true" readonly clickable/>
            <van-popup v-model="showStatusPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="statusArr"
                        @confirm="onStatusConfirm"
                        @cancel="showStatusPicker = false"
                        value-key="name"
                />
            </van-popup>
            <!-- 方案选择 -->
            <van-field class="field" :value="planValue"  @click="showPlanPicker = true" readonly clickable/>
            <van-popup v-model="showPlanPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="planArr"
                        @confirm="onPlanConfirm"
                        @cancel="showPlanPicker = false"
                        value-key="name"
                />
            </van-popup>
            
        </div>
       

        <div :class="getIndexStyle">
             <van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功">
                <van-list
                    v-model="loading" 
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >     
                <div :class="getCustomer" v-for="(item,index) in staffArr" :key="index" @click="toDetail(item.id,item.progress)">
                    <p class="firstName" v-show="userType == 'Headquarters'">经销商名称:{{item.distributorName}}</p>
                    <p class="firstName" v-show="userType == 'customer' || userType == 'salesman'">申请时间:{{item.createTime}}</p>
                    <p v-show="userType == 'distributor'" class="firstName">业务员名称:{{item.salesman}}</p>
                    <p class="title">{{item.schemeType == 1 ?'折扣方案':'储能方案'}}</p>
                    <p class="name">客户名称:{{item.customerName}}</p>
                    <div class="detail">查看详情</div>

                    <img src="../../assets/image/customerList/put.png" v-if="item.progress == 1" alt="" class="statusImg">
                    <img src="../../assets/image/customerList/check.png" v-if="item.progress == 2" alt="" class="statusImg">
                    <img src="../../assets/image/customerList/newAdd.png" v-if="item.progress == 0" alt="" class="statusImg">
                    <img src="../../assets/image/customerList/reject.png" v-else-if="item.progress == 3"  alt="" class="statusImg">
                    <img src="../../assets/image/customerList/sendOrder.png" v-if=" item.progress == 4" alt="" class="statusImg">
                   
                </div>
                    
                </van-list>
               </van-pull-refresh>
        </div> 

    </div>
</template>
<script>

import {getList,getSalesman,getDistributor} from '../../network/addMes'

    export default{
        name:'Login',
        data(){
            return{
                userType:'',//用户类型
                isFixed:true,
                loading: false,
                finished: false,
                pageNum:1,
                pageSize:6,
                total:0,
                refreshing:false,
                staffArr:[],
                statusArr:[{name:'新建',value:'0'},{name:'提交',value:'1'},{name:'审批中',value:'2'},{name:'已派单',value:'4'},{name:'驳回',value:'3'}],
                scheduleControl:'',//选择方案
                progress:'',//审核状态
                titleName:'',//选择经销商
                salesman:'',//选择业务员

                distributorValue:'选择业务员',
                distributorArr:[],//业务员列表
                showDistributorPicker:false,

                headerValue:'选择经销商',
                showHeaderPicker:false,
                headerArr:[],

                planValue: '选择方案',
                planArr: [{name:'选择方案',value:''},{name:'折扣方案',value:'discount_scheme'},{name:'储能方案',value:'storage'}],
                showPlanPicker: false,

                statusValue: '审批状态',
                showStatusPicker: false,

            }
        },
        methods:{
            onHeaderConfirm(value){//经销商选择
                this.headerValue = value.titleName;
                this.titleName =  value.titleName;
                this.showHeaderPicker = false;
                this.onRefresh();
            },
            onDistributorConfirm(value){//业务员选择
                this.distributorValue = value.salesman;
                this.salesman = value.salesman
                this.showDistributorPicker = false;
                this.onRefresh();
            },
            onPlanConfirm(value) {//方案选择
                this.planValue = value.name;
                this.showPlanPicker = false;
                this.scheduleControl = value.value
                this.onRefresh();
            },
            onStatusConfirm(value){//状态刷选
                this.statusValue = value.name;
                this.progress = value.value;
                this.showStatusPicker = false;
                this.onRefresh();
            },

            onClickSelectStatus(index,name){
                console.log(index)
                switch (name) {
                    case '新建':
                            this.progress = 0 ;
                        break;
                    case '提交':
                            this.progress = 1 ;
                        break;
                    case '审批中':
                            this.progress = 2 ;
                        break; 
                    case '已派单':
                            this.progress = 4 ;
                        break; 
                    case '驳回':
                            this.progress = 3 ;
                        break;
                    default:
                        break;

                }
                this.onRefresh();
            },
            addCustomer(){
                this.$router.replace('/powerMeasure');
            },
            getDataList(progress,scheduleControl,salesman,titleName){
                let userID = sessionStorage.getItem('userId');
                    getList(progress,scheduleControl,salesman,titleName,userID,this.pageNum,this.pageSize).then((res)=>{
                            if(res.success){    
                                if(res.data.isLastPage){
                                    this.finished = true
                                } 
                                this.total = res.total
                                
                                for (const iterator of res.data.list) {
                                    this.staffArr.push(iterator)
                                }
                                this.loading = false;
                                this.refreshing = false
                                if (this.staffArr.length >= res.total) {
                                    this.finished = true;		// 结束加载状态
                                   
                                }
                                this.pageNum++;				    
                            }else{
                                this.$toast.fail(res.msg)
                            }
                    })
            },
                onLoad() { 
                    
                    if(this.userType == 'salesman' && this.progress == ''){
                        this.progress = 0;
                    }  
                    this.getDataList(this.progress,this.scheduleControl,this.salesman,this.titleName);				
                },
                onRefresh(){
                    this.staffArr = [];
                    this.finished = false;
                    this.loading = true;
                    this.pageNum = 1;
                    this.onLoad();
                },
                toDetail(id,progress){
                    this.$router.push({
                        path:'/basicMessage',
                        query:{
                            id,
                            progress
                        }
                    })
                },
                toselect(selectPlan){
                    this.scheduleControl =  selectPlan;
                    this.onRefresh(this.progress,this.scheduleControl,this.salesman,this.titleName);
                }
        },
        mounted(){
             document.querySelector('body').setAttribute('style','background-color:#F7F7F7');
           
            //Headquarters总部 distributor经销商老板 salesman 业务员 customer客户
               
                    this.userType = sessionStorage.getItem('identity');
                    if(this.userType == 'Headquarters'){
                        getDistributor(sessionStorage.getItem('userId')).then((res)=>{
                            if(res.success){
                               this.headerArr =  res.data;
                            }
                        })
                    }
                if(this.userType == 'distributor'){
                    getSalesman(sessionStorage.getItem('userId')).then((res)=>{
                        if(res.status){
                          this.distributorArr = res.data;
                        }
                    })
                }
                    
                
        },
        computed:{
            getIndexStyle(){
                
                if(this.userType == 'customer'){
                    return 'staffCustomerList';
                }else if(this.userType == 'salesman'){
                    return 'StaffList';
                }else{
                    return 'staffBossList'
                }
            },
            getCustomer(){
                if(this.userType == 'customer' || this.userType == 'salesman'){
                    return 'customerDiv';
                }
                else{
                    return 'headquartersDiv'
                }
            }

        }
      
      
    }
   
</script>
<style lang="less" scoped>
    //  @menuBar: 360px;
    //  @myHeight: calc(~"100vh -  @{menuBar}");
    :deep(.van-pull-refresh){
    //     // height:calc(100vh - 360px);
         overflow: scroll;
    //     height:@myHeight;
        height: 100%;
     }
</style>

<style scoped>
.selectPlan{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100px;
}
.selectPlan div{
    text-align: center;
    flex: 1;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
}
.selectActive{
    color: #01A8A8 !important;
}
.center{
    border-left: solid 1px #010101;
    border-right: solid 1px #010101;
}
.basic{
     margin-top: 100px;
    /* height:calc(100vh - 200px);*/ 
        overflow: scroll; 
}

.addDiv{
    position:fixed;
    right: 0px;
    bottom: 100px;
    z-index: 999;
}
.addDiv img{
    width: 123px;
}

.customerDiv{
    position: relative;
    background: #FFFFFF;
    width: 94%;
    margin: 0px auto;
    margin-bottom: 20px;
    height: 300px;

}
.headquartersDiv{
    position: relative;
    background: #FFFFFF;
    width: 94%;
    margin: 0px auto;
    margin-bottom: 20px;
    height: 300px;
}
.statusImg{
    width: 110px;
    position: absolute;
    top: 0;
    right:0;
}
.title{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    padding-top: 30px;
    padding-left: 41px;
    margin-left: 0px;
    line-height: 30px;
}
.firstName{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    padding-top: 36px;
    padding-left: 41px;
    margin-left: 0px;
    line-height: 30px;
}
.name{
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-top: 24px;
    padding-left: 42px;
}
.detail{
    width: 146px;
    line-height: 50px;
    border: 1px solid #01A8A8;
    border-radius: 25px;
    text-align: center;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A8A8;
    margin-top: 32px;
    margin-left: 40px;
}
.isboss{
    display: flex;
    align-items: center;
    justify-content: left;
}
.isboss select{
    
    
    border: 1px solid #333333;
    border-radius: 23px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    padding:5px  10px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.StaffList{
     height:calc(100vh - 360px);
    overflow: scroll;
}
.staffCustomerList{
    height:calc(100vh - 200px);
    overflow: scroll;
}
.staffBossList{
    height:calc(100vh - 300px);
    overflow: scroll;
}
select {
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    padding: 5px 20px;
    margin: 20px 20px;
    border: 1px solid #333333;
    border-radius: 23px;

}
.field{
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    padding: 5px 20px;
    margin: 20px 20px;
    border: 1px solid #333333;
    border-radius: 23px;
    width: 165px;
    text-align: center;
}
</style>
